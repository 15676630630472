import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";

import { contextIsAdminPortal, contextPortalUser } from '../App.js'

import { Form, Col, Row, Button, Stack, Badge, Placeholder, Spinner, Tab, Tabs } from "react-bootstrap";

import DatasetInfo from "../components/DatasetInfo";
import HeaderNew from "../components/HeaderNew";
import DataCard from "../components/DataCard";

import { status_draft, permission_admin } from '../constants/omp_constant.js';

import { api_config } from "../components/api_url";

import { useCustomNavigate } from '../hooks/useCustomNavigate';

import { useSpring, animated } from '@react-spring/web';

import { toast } from "react-toastify";

import { DATA_MD_CITATION_EXTRACT_REGEX, TEMP_EDIT_ID_REGEX } from '../constants/regex';
import { no_dataset_detail } from "../constants/omp_ui_text";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { prepareRule } from "react-querybuilder";

const ViewDatasetByName = () => {
  const api_url = api_config.url.API_URL

  const [isAdminPortal] = useContext(contextIsAdminPortal);
  const [portalUser, setPortalUser] = useContext(contextPortalUser);

  const [dataset, setDataset] = useState([]);
  const [data_meta, setDataMeta] = useState({});
  const [filteredInput, setFilteredInput] = useState();
  const [dataInDatasetVersioned, setDataInDatasetVersioned] = useState([]);
  const [originalDataInDatasetVersioned, setOriginalDataInDatasetVersioned] = useState([]);

  const [accessToken, setAccessToken] = useState();
  const [cart, setCart] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [editdsid, setEditDsId] = useState();
  const [editdid, setEditDId] = useState();
  const [edit_session_id, set_edit_session_id] = useState()
  const [editVersion, setEditVersion] = useState();

  const [uploadResponse, setUploadResponse] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [keyword, setKeyword] = useState('');

  const params = useParams();
  const location = useLocation();
  const navigate = useCustomNavigate();

  let localCart;

  const getCurrentDateTime = () => {
    const today = new Date(Date.now());
    const current_date_time =
      today.getFullYear().toString() + "-" +
      (today.getMonth() + 1).toString().padStart(2, '0') + "-" +
      today.getDate().toString().padStart(2, '0') + " " +
      today.getHours().toString().padStart(2, '0') + ":" +
      today.getMinutes().toString().padStart(2, '0') + ":" +
      today.getSeconds().toString().padStart(2, '0');

    return current_date_time
  }

  let new_dataset_template = {
    contact: "",
    data_id: "",
    dataset_desc: "",
    dataset_id: "",
    last_updated_date: "",
    permission: permission_admin,
    publish_date: getCurrentDateTime(),
    status: status_draft,
    tag: "",
    title: "",
    title_abbr: ""
  }

  let new_data_template = {
    allow_access: true, //Temp value for editing screen
    message: "This is a new data", //Temp value for editing screen
    data_id: "", //Not Null
    tag: "",
    data_desc: "",
    data_name: "", //Not Null
    is_spatial: false, //Not Null
    format: "", //Not Null
    permission: permission_admin, //Not Null
    publish_date: getCurrentDateTime(),
    last_updated_date: "",
    last_updated_by: "",
    create_date: "",
    created_by: "",
    status: "", //Not Null
    contact: "",
    category: "",
    title: "",
    data_columns: null,
    data_rows: null,
    version: 1
  }

  let temp_new_dataset_id = '';

  //Set metadata
  useEffect(() => {
    //Include Google Analytics Tag
    const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
    // Google Analytics tracking code
    const head = document.querySelector("head");
    const script1 = document.createElement("script");
    script1.async = true;
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
    head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerText =
      `window.dataLayer = window.dataLayer || [];\
        function gtag(){dataLayer.push(arguments);}\
        gtag('js', new Date()); \
        gtag('config', '${trackingID}');\
        gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
    head.appendChild(script2);

    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | ' + params.dataset;
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality Dataset - ' + params.dataset);

    // Clean up the meta tag modifications when the component unmounts
    return () => {
      document.title = '';
      document.querySelector('meta[name="description"]').setAttribute('content', '');
      head.removeChild(script1);
      head.removeChild(script2);
    };
  }, []);

  useEffect(() => {
    const fetchDatasetInfo = async () => {
      try {
        let ds;
        if (!location.state) {
          ds = await (isAdminPortal
            ? getDatasetInfoByTitleAbbrAdmin()
            : getDatasetInfoByTitleAbbr()
          );
          setDataset(ds);
        }
      } catch (error) {
        // Handle and log any potential errors during the API call
        console.error("Error fetching dataset info: ", error);
      }
    };

    if (!location.state?.dataset) fetchDatasetInfo();
  }, [isAdminPortal]);

  useEffect(() => {
    const fetchDataInDataset = async () => {
      try {
        let dataInDatasetVersioned;
        if (isAdminPortal) {
          dataInDatasetVersioned = await getDataInDatasetByDatasetAbbrVersionedAdmin();

          if (dataInDatasetVersioned?.data?.length > 0) {
            const userIdsString = await getUniqueUserIds(dataInDatasetVersioned.data);
            const dataMetaUsersInfo = await getUserByIds(userIdsString);
            setDataMeta(dataMetaUsersInfo);
          }
        } else {
          dataInDatasetVersioned = await getDataInDatasetByDatasetAbbrVersioned();
        }
        setDataInDatasetVersioned(dataInDatasetVersioned);
        //Keep the original data when filtering finish
        setOriginalDataInDatasetVersioned(dataInDatasetVersioned);
      } catch (error) {
        // Handle and log any potential errors during data fetching
        console.error("Error fetching data in dataset: ", error);
      }
      //setIsLoading(false);
    };

    // Fetch data in the dataset when the component mounts or isAdminPortal changes
    fetchDataInDataset();
  }, [isAdminPortal]);

  useEffect(() => {
    localCart = localStorage.getItem("cart");
    if (localCart) {
      //turn the cart content in local storage into js
      localCart = JSON.parse(localCart);
      //load persisted cart into state if it exists
      setCart(localCart)
    }
  }, []);

  useEffect(() => {
    //Data filerting - Revision on 2024-04-11 after introduced version on data
    if (filteredInput !== "" && filteredInput !== null) {

      // Create a copy of filteredDataInDataset to update      
      let updatedFilteredDataInDataset = { ...dataInDatasetVersioned };
      const filterkeyword = filteredInput?.trim()?.toUpperCase();

      const filteredData = updatedFilteredDataInDataset?.data
        ?.map(sublist => sublist.filter(item =>
          (item?.data_name?.toUpperCase()?.includes(filterkeyword)) ||
          (item?.tag?.toUpperCase()?.includes(filterkeyword)) ||
          (item?.data_desc?.toUpperCase()?.includes(filterkeyword))
        ))
        ?.filter(sublist => sublist?.length > 0);

      updatedFilteredDataInDataset = {
        ...updatedFilteredDataInDataset,
        data: filteredData,
        message: filteredData?.length > 0 ? "" : "No Data Found with the keyword: " + filteredInput
      };

      setDataInDatasetVersioned(updatedFilteredDataInDataset)
    }
    //setIsLoading(false)
  }, [filteredInput]);

  useEffect(() => {
    if ((dataInDatasetVersioned?.data?.length > 0 || (dataInDatasetVersioned?.data?.length === 0 && dataInDatasetVersioned?.message)) && isLoading) {
      setIsLoading(false)
    }
  }, [dataInDatasetVersioned])

  const handleNonExistenceDS = () => {
    navigate('/ErrorPage')
  }

  //get login user
  const getUser = () => {
    return new Promise((resolve, reject) => {
      //setIsLoading(true)
      try {
        fetch(api_url + "/user/getUser", {
          method: 'GET',
          credentials: 'include'
        })
          .then((res) => res.text()) //return res.text for getUser() to avoid JSON parsing error; the passport.js return null when there is no user in session
          .then((res_json) => {
            if (res_json?.length > 0) {
              resolve(JSON.parse(res_json)) //If there is user, parse the response to JSON
              //setIsLoading(false)
            } else {
              resolve(null)
              //setIsLoading(false)
            }
          })
          .catch((err) => {
            reject(err)
            //setIsLoading(false)
          });
      }
      catch (err) {
        reject(err)
        //setIsLoading(false)
      }
    })
  }

  const refreshToken = () => {
    //setIsLoading(true)
    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/user/refresh", {
        method: "GET",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((myJson) => {
          setAccessToken(myJson)
          resolve(myJson)
          //setIsLoading(false)
        });
    })
  }

  const getDatasetInfoByTitleAbbrAdmin = async () => {
    return new Promise(async (resolve, reject) => {
      //setIsLoading(true)
      fetch(api_url + "/admin/dataset/" + encodeURIComponent(params.dataset), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((datasetInfo) => {
          if (datasetInfo.data.length > 0) {
            resolve(datasetInfo.data);
          }
          else {
            resolve(datasetInfo);
          }
          //setLoading(false)
          //setIsLoading(false)
        });

    });
  };

  const getDatasetInfoByTitleAbbr = async () => {
    return new Promise(async (resolve, reject) => {
      //setLoading(true)
      //setIsLoading(true)
      fetch(api_url + "/dataset/" + encodeURIComponent(params.dataset), {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((datasetInfo) => {
          if (datasetInfo.data.length > 0) {
            resolve(datasetInfo.data);
          }
          else {
            resolve(datasetInfo);
          }
          //setLoading(false)
          //setIsLoading(false)
        });

    });
  };

  const getDataInDatasetByDatasetAbbrVersioned = async () => {
    //setLoading(true)
    //setIsLoading(true)
    //Refresh access token    
    const accessToken = await refreshToken();
    setAccessToken(accessToken)

    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/dataset/" + params.dataset.toLowerCase() + "/data/version", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(res => res.json())
        .then(res_json => {
          //setLoading(false);
          //setIsLoading(false);
          resolve(res_json);
        })
        .catch(err => {
          //setIsLoading(false);
          console.log(err)
        })
    });
  };

  const getDatasetsByDataIdAdmin = async (data_id) => {
    //Refresh access token    
    const accessToken = await refreshToken();

    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/admin/data/" + data_id + "/datasets", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
      })
        .then(res => res.json())
        .then(res_json => {
          resolve(res_json);
        })
        .catch(err => {
          console.log(err)
        })
    });
  };

  const getDataInDatasetByDatasetAbbrVersionedAdmin = async () => {
    //setLoading(true)
    //setIsLoading(true)
    //Refresh access token    
    const accessToken = await refreshToken();
    setAccessToken(accessToken)

    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/admin/dataset/" + params.dataset.toLowerCase() + "/data/version", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(res => res.json())
        .then(res_json => {
          //setLoading(false);
          //setIsLoading(false)
          resolve(res_json);
        })
        .catch(err => {
          console.log(err)
          //setIsLoading(false)
        })
    });
  };

  const handleDownload = (item) => {
    //create a copy of our cart state, avoid overwritting existing state    
    let cartCopy = [...cart];
    //assuming we have an ID field in our item
    let { ID } = item;
    //look for item in cart array
    let existingItem = cartCopy.find(cartItem => cartItem.ID === ID);
    //if item already exists
    if (existingItem) {
      cartCopy.splice(cartCopy.indexOf(existingItem), 1);
      toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({ name: 'circle-minus' })} />
        <span> Removed <strong>{existingItem.supp_info.data_name}</strong></span></div>)
    } else if (cartCopy.length >= 10) {
      toast(<div className="text-center align-items-center" style={{ color: "red" }}><FontAwesomeIcon size="1x" icon={icon({ name: 'triangle-exclamation' })} />
        <span> Limit of 10 items per download has been reached.</span></div>)
    }
    else { //if item doesn't exist, simply add it
      cartCopy.push(item);
      toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({ name: 'circle-plus' })} />
        <span> Added <strong>{item.supp_info.data_name}</strong> </span></div>)
    }
    //update app state
    setCart(cartCopy)
    //make cart a string and store in local space
    let stringCart = JSON.stringify(cartCopy);
    localStorage.setItem("cart", stringCart)
  }

  const handleFilterChange = (e) => {
    if (e.target.value === "") {
      setDataInDatasetVersioned(originalDataInDatasetVersioned)
    }

    setFilteredInput(e.target.value)
  }

  //Extract Citation and display
  const CitationDisplay = ({ data_desc_md }) => {
    return (
      <>
        <div className="p-2 m-2">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            children={
              !!data_desc_md
                ?
                data_desc_md?.dataset_desc?.match(DATA_MD_CITATION_EXTRACT_REGEX)
                  ? data_desc_md?.dataset_desc?.match(DATA_MD_CITATION_EXTRACT_REGEX)[1]?.trim()
                  : "_No Citation available_"
                : ""
            }
          >
          </ReactMarkdown>
        </div>
      </>
    )
  }

  const addData = async (new_data) => {
    //Refresh access token
    //setIsLoading(true)
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/admin/data", {
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
        body: JSON.stringify(new_data)
      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.status) {
            //setIsLoading(false)
            resolve(myJson)
          } else {
            //setIsLoading(false)
            reject(myJson)
          }
        });
    });
  }

  const updateData = async (update_data, data_id) => {
    //Refresh access token
    //setIsLoading(true)
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      fetch(`${api_url}/admin/data/${data_id}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
        body: JSON.stringify(update_data)
      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.status) {
            //setIsLoading(false)
            resolve(myJson)
          } else {
            //setIsLoading(false)
            reject(myJson)
          }

        });
    });
  }

  const deleteData = async (data_id) => {
    //Refresh access token
    const accessToken = await refreshToken();

    return new Promise(async (resolve, reject) => {
      fetch(`${api_url}/admin/data/${data_id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        }
      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.status) {
            resolve(myJson)
          } else
            reject(myJson)
        });
    });
  }

  const dropDataTable = async (data_table) => {
    //Refresh access token
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/admin/datatable/" + data_table, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        }
      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.status)
            resolve(myJson)
          else
            reject(myJson)
        });
    });
  }

  const deleteDataInFolder = async (temp_data_name) => {
    //Refresh access token
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/admin/folderdata/" + temp_data_name, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        }
      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.status)
            resolve(myJson)
          else
            reject(myJson)
        });
    });
  }

  const deleteDataInTempFolder = async (edit_session_id) => {
    //Refresh access token
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      fetch(`${api_url}/admin/tempfolderdata/${edit_session_id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        }
      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.status)
            resolve(myJson.data)
          else
            reject(myJson)
        });
    });
  }

  const moveDataToDownload = async (data_name, custom_data_name, data_format, data_id, edit_session_id) => {
    //Refresh access token
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      //fetch(`${api_url}/admin/tempfolderdata/${edit_session_id}/temp_file_${data_id}_${data_name}.${data_format}`, {
      fetch(`${api_url}/admin/tempfolderdata/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
        body: JSON.stringify({
          edit_session_id: edit_session_id,
          temp_data_name: `temp_file_${data_id}_${data_name}`,
          custom_data_name: custom_data_name,
          format: `${data_format}`
        })
      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.status)
            resolve(myJson)
          else
            reject(myJson)
        });
    });
  }

  const renameTable = async (data_name, data_id, edit_session_id, custom_data_name) => {
    let original_table_name;
    let new_table_name;

    //Set original and new table name for exisitng data
    if (data_id.toString().match(/[A-Za-z]/g)?.length === undefined) {
      original_table_name = data_name
      new_table_name = custom_data_name
    }
    //Set original and new table name for new data and No custom data name defined
    else if ((data_id.toString().match(/[A-Za-z]/g)?.length > 0) && (data_name === custom_data_name)) {
      original_table_name = `temp_tab_${edit_session_id}_${data_id}_${data_name}`
      new_table_name = data_name
    }
    //Set original and new table name for new data and custom data name defined
    else {
      original_table_name = `temp_tab_${edit_session_id}_${data_id}_${data_name}`
      new_table_name = custom_data_name
    }

    //Refresh access token    
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      fetch(`${api_url}/admin/datatablename/`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
        body: JSON.stringify({
          original_table_name: original_table_name,
          new_table_name: new_table_name
        })
      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.result)
            resolve(myJson)
          else
            reject(myJson)
        });
    });
  }

  const renameDataFile = async (old_data_name, new_data_name) => {
    //Refresh access token    
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      fetch(`${api_url}/admin/folderdata/`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
        body: JSON.stringify({
          data_name: old_data_name,
          new_data_name: new_data_name
        })

      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.status)
            resolve(myJson)
          else
            reject(myJson)
        });
    });
  }

  const updateDataset = async (updated_dataset) => {
    //Refresh access token
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/admin/dataset/" + updated_dataset.dataset_id, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
        body: JSON.stringify(updated_dataset)
      })
        .then((res) => res.json())
        .then((myJson) => {
          if (myJson?.status)
            resolve(myJson)
          else
            reject(myJson)
        });
    });
  }

  const getDatasetByDataId = async (data_id) => {
    //Refresh access token    
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      setUploadResponse({ message: 'Uploading...' })
      setUploading(true)
      fetch(api_url + "/dataset/data_id/" + data_id, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        }
      })
        .then((res) => res.json())
        .then(async (myJson) => {
          if (myJson.result) {
            resolve(myJson)
          } else {
            reject(myJson)
          }
        });
    });
  }

  const batchUploadFiles = async (meta_data_file, data_dict, data_file, data_id, edit_session_id, overwrite_data) => {
    console.log("File upload start...")
    setUploadResponse({ message: 'Uploading...' })
    try {
      //Refresh access token    
      const accessToken = await refreshToken();

      //Create form data
      const formData = new FormData();

      formData.append('data_id', data_id);
      formData.append('edit_session_id', edit_session_id);
      formData.append('metadata_filecount', 1);
      formData.append('metadata', meta_data_file);
      formData.append('data_dictionary_filecount', 1);
      formData.append('data_dictionary', data_dict);
      formData.append('data_filecount', data_file?.length ? data_file.length : 1);

      if (data_file?.length) {
        for (let i = 0; i < data_file.length; i++) {
          formData.append('data', data_file[i]); // 'files' is the key, and it appends each file to the same key
        }
      } else {
        formData.append('data', data_file);
      }

      //formData.append('mode', 'batch');
      formData.append('overwrite_data', overwrite_data);

      return new Promise(async (resolve, reject) => {
        setUploading(true)

        // Create an instance of AbortController
        const controller = new AbortController();

        const timeoutId = setTimeout(() => {
          console.log("Timed out...");
          controller.abort();
        }, 36000000);

        fetch(api_url + "/admin/data/fileupload2", {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken.token
          },
          body: formData,
          signal: controller.signal
        })
          .then((res) => res.json())
          .then(async (res_json) => {
            clearTimeout(timeoutId);
            setUploadResponse(res_json)

            if (res_json?.upload_success && (res_json?.processed_file.length > 0 || res_json?.overwritten_file.length > 0)) {

              //Get the attributes for current editing dataset
              const dataset_to_be_updated = location.state?.dataset?.length > 0 ? location.state?.dataset[0] : dataset[0]

              //Convert data_id to number array and sort
              let data_id_in_dataset;
              if (dataset_to_be_updated["data_id"])
                data_id_in_dataset = dataset_to_be_updated["data_id"].replaceAll(" ", "").trim().split(",").map(Number).slice().sort();

              //Get data_id to be updated from return JSON
              let data_id_to_be_updated;
              if (res_json.processed_file_id.length > 0) {
                data_id_to_be_updated = res_json?.processed_file_id.map(Number).slice().sort();
              }

              if (res_json.overwritten_file_id.length > 0) {
                data_id_to_be_updated = res_json?.overwritten_file_id.map(Number).slice().sort();
              }

              //Check if there is existinf data_id in the dataset, if so, concat with it
              if (!!data_id_in_dataset && data_id_to_be_updated.filter(value => !data_id_in_dataset?.includes(value))) {
                data_id_to_be_updated = data_id_to_be_updated.filter(value => !data_id_in_dataset?.includes(value))
                dataset_to_be_updated.data_id = data_id_in_dataset.concat(data_id_to_be_updated).join(',');
              } else {
                dataset_to_be_updated.data_id = data_id_to_be_updated.join(',');
              }

              dataset_to_be_updated.last_updated_date = getCurrentDateTime()
              dataset_to_be_updated.last_updated_by = portalUser?.uid

              //Update dataset
              const update_dataset_result = await updateDataset(dataset_to_be_updated)

              //Update dataset in state
              setDataset(prevVal => [
                { ...prevVal[0], data_id: update_dataset_result?.data_id },
                ...prevVal.slice(1)
              ])

              if (res_json?.skipped_file.length > 0 || res_json?.overwritten_file.length > 0) {
                toast(
                  <div className="text-center align-items-center">
                    <FontAwesomeIcon size="1x" icon={icon({ name: 'cloud' })} /><span>&nbsp;File(s) processed; some files have been skipped/overwritten</span>
                  </div>
                )
              } else {
                //handling auto close after upload
                setUploading(false)
                setIsEditing(false)

                //Refresh the page and update the state
                const dataIndatasetVerioned = await getDataInDatasetByDatasetAbbrVersionedAdmin()
                setDataInDatasetVersioned(dataIndatasetVerioned)

                //Remove the temp data card from array
                if (dataInDatasetVersioned?.data?.filter((d) => TEMP_EDIT_ID_REGEX.test(d.data_id) === true)) {
                  dataInDatasetVersioned?.data?.shift()
                }

                toast(
                  <div className="text-center align-items-center">
                    <FontAwesomeIcon size="1x" icon={icon({ name: 'cloud' })} /><span>&nbsp;File(s) Uploaded</span>
                  </div>
                )

                //Navigate to dataset page
                navigate("/dataset/" + update_dataset_result?.data?.title_abbr?.toLowerCase(), {
                  state: {
                    dataset: [update_dataset_result?.data]
                  }
                });

                resolve(res_json)
              }
            } else {
              setUploading(false)
              setUploadResponse(res_json)
              reject(res_json)
            }

          });
      });
    } catch (err) {
      console.log("batch upload file error", err)
    }
  }

  /********************/
  /* Dataset Handling */
  /********************/
  const handleAddDataset = async () => {

    //If there is NO new dataset (i.e. no dataset_id contains letters) on screen
    /*if ( dataset.filter( (ds) => ds.dataset_id.toString().match(/[A-Za-z]/g))?.length === 0)
    {            
        SetIsEditing(true)        
        temp_new_dataset_id = Math.random().toString(36).slice(2);
        setEditDsId(temp_new_dataset_id)
        let temp_datasets = [...dataset]
        new_dataset.dataset_id = temp_new_dataset_id
        temp_datasets.unshift(new_dataset)
        setDatasets(temp_datasets)
    }
    else
    {
        window.confirm("There is an new dataset is currently editing")
    }*/

  }

  const handleEditDataset = (dataset_id) => {
    setIsEditing(true)
    setEditDsId(dataset_id)
  }

  const handleSaveDataset = async (ds_id, ds_title, ds_title_abbr, ds_description, ds_publish_date, ds_contact, ds_data_id, ds_permission, ds_status, ds_tag) => {
    if (ds_id.toString().match(/[A-Za-z]/g)?.length > 0) {
      /*try {
        //New dataset
        //Set dataset access permission to `admin` by default
        new_dataset.permission = permission_admin;
        //Set dataset publish status to `drafted` by default
        new_dataset.status = status_draft;
        //Remove temp dataset ID
        delete new_dataset['dataset_id']
   
        new_dataset.title = ds_title
        new_dataset.title_abbr = ds_title_abbr
        new_dataset.dataset_desc = ds_description
        new_dataset.publish_date = ds_publish_date
   
        const add_dataset_result = await addDataset(new_dataset)
   
        //Remove temp dataset from array
        datasets.shift();
        await listAlldata()
        toast(<div className="text-center align-items-center"><span><strong>{add_dataset_result?.title}</strong>&nbsp;Added</span></div>)
        SetIsEditing(false)
        setEditDsId()
      } catch (err) {
        toast(<div className="text-center align-items-center"><span className='text-danger'>There is problem on adding dataset - <strong>&nbsp;{err?.message?.detail}</strong></span></div>)
      }*/

    } else {
      let updated_dataset;
      updated_dataset = location.state?.dataset ? location.state?.dataset[0] : dataset
      if (ds_title !== updated_dataset?.title) updated_dataset.title = ds_title
      if (ds_title_abbr !== updated_dataset?.title_abbr) updated_dataset.title_abbr = ds_title_abbr.toLowerCase()
      if (ds_description !== updated_dataset?.dataset_desc) updated_dataset.dataset_desc = ds_description
      if (ds_publish_date !== updated_dataset?.publish_date) updated_dataset.publish_date = ds_publish_date
      if (ds_contact !== updated_dataset?.contact) updated_dataset.contact = ds_contact
      if (ds_data_id !== updated_dataset?.data_id) updated_dataset.data_id = ds_data_id
      if (ds_permission !== updated_dataset?.permission) updated_dataset.permission = ds_permission
      if (ds_status !== updated_dataset?.status) updated_dataset.status = ds_status
      if (ds_tag !== updated_dataset?.tag) updated_dataset.tag = ds_tag

      try {
        const update_dataset_result = await updateDataset(updated_dataset)
        setIsEditing(false)
        setEditDsId()
        toast(<div className="text-center align-items-center"><span><strong>{update_dataset_result.data.title}</strong>&nbsp;Updated</span></div>)
        //await listAlldata()
      } catch (err) {
        toast(<div className="text-center align-items-center"><span className='text-danger'>Fail to update dataset - &nbsp;<strong>{err?.message}</strong></span></div>)
      }
    }
  }

  const handleCancelEditDataset = async (dataset_id) => {
    if (dataset_id.toString().match(/[A-Za-z]/g)?.length > 0) {
      dataset.shift()
    }

    setIsEditing(false)
    setEditDsId()
  }

  const handleDeleteDataset = async (dataset_id) => {
    //New dataset
    const ds_to_be_removed = dataset.filter(ds => ds.dataset_id === dataset_id)[0]

    const confirmDelete = window.confirm(`Are you sure you want to delete '${ds_to_be_removed.title}'?`);
    if (confirmDelete) {
      try {
        //const result = await deleteDataset(ds_to_be_removed.dataset_id)                      
        //toast(<div className="text-center align-items-center"><span><strong>{result?.data?.title}</strong>&nbsp;deleted</span></div> )
      } catch (err) {
        toast(<div className="text-center align-items-center"><span className='text-danger'>There is problem in deleting - <strong>{ds_to_be_removed?.title}</strong>&nbsp;deleted</span></div>)
      }
      //await listAlldata()            
    } else {
      toast(<div className="text-center align-items-center"><span className='text-danger'>Cancel Deleting&nbsp;<strong>{ds_to_be_removed.title}</strong></span></div>)
    }
  }
  /*******************/
  /*  Data Handling  */
  /*******************/
  const handleAddData = async () => {
    let temp_new_data_id = '';
    let new_edit_session_id = '';
    //If there is NO new dataset (i.e. no dataset_id contains letters) on screen    
    if (dataInDatasetVersioned?.data?.filter((d) => TEMP_EDIT_ID_REGEX.test(d.data_id) === false)?.length === 0) {
      //Initialize state variables for data editing
      setIsLoading(false)
      //Set default edit version to 1
      setEditVersion(1)

      //Initialize state variables for data editing
      setUploadResponse(null)
      setIsEditing(true)

      temp_new_data_id = Math.random().toString(36).slice(2, 12);
      setEditDId(temp_new_data_id)

      new_edit_session_id = Math.random().toString(36).slice(2, 12);
      set_edit_session_id(new_edit_session_id)

      new_data_template.data_id = temp_new_data_id

      //Duplicate data 
      let dataWithNewDataTemplate = { ...dataInDatasetVersioned }

      //Include new data template with versioned=1 into the array
      dataWithNewDataTemplate.data = dataWithNewDataTemplate.data?.unshift([new_data_template])
      //setDataInDatasetVersioned(dataWithNewDataTemplate)
    }
    else {
      window.confirm("There is an new dataset is currently editing")
    }
  }

  const handleEditData = (data_id, edit_version) => {
    setEditVersion(edit_version)
    setEditDId(data_id)
    //setUploadResponse(null)
    let new_edit_session_id = '';
    new_edit_session_id = Math.random().toString(36).slice(2, 12);
    set_edit_session_id(new_edit_session_id)
    setIsEditing(true)
  }

  const handleSaveData = async (d_id, d_tag, d_data_desc, d_data_name, d_custom_data_name, d_is_spatial, d_format, d_permission, d_publish_date, d_status, d_contact, d_category, d_title, d_data_columns, d_data_rows) => {
    if (d_id.toString().match(/[A-Za-z]/g)?.length > 0) {
      try {
        //New data        
        //Remove temp dataset ID
        delete new_data_template['data_id']
        delete new_data_template['allow_access']
        delete new_data_template['message']
        delete new_data_template['last_updated_date']
        new_data_template.tag = d_tag
        new_data_template.data_desc = d_data_desc
        new_data_template.data_name = d_custom_data_name !== d_data_name ? d_custom_data_name : d_data_name
        new_data_template.is_spatial = d_is_spatial
        new_data_template.format = d_format
        new_data_template.permission = d_permission
        new_data_template.publish_date = d_publish_date
        new_data_template.status = d_status
        new_data_template.contact = d_contact
        new_data_template.category = d_category
        new_data_template.title = d_title
        new_data_template.data_columns = d_data_columns
        new_data_template.data_rows = d_data_rows

        const addDataResult = await addData(new_data_template)

        const moveDataFiles = await moveDataToDownload(d_data_name, d_custom_data_name, d_format, d_id, edit_session_id)

        const renameDataTableResult = await renameTable(d_data_name, d_id, edit_session_id, d_custom_data_name)

        if (addDataResult?.status && moveDataFiles?.status && renameDataTableResult?.status) {
          let updated_dataset = await getDatasetInfoByTitleAbbrAdmin()
          updated_dataset = updated_dataset[0]
          if (updated_dataset["data_id"] === null || updated_dataset["data_id"] === "")
            updated_dataset["data_id"] = addDataResult?.data.data_id
          else
            updated_dataset["data_id"] = `${updated_dataset["data_id"]},${addDataResult?.data.data_id}`

          updateDataset(updated_dataset)
            .then(async (updateDatasetResult) => {

              const dataIndatasetVerioned = await getDataInDatasetByDatasetAbbrVersionedAdmin()
              setDataInDatasetVersioned(dataIndatasetVerioned)

              //Refresh the page and update the state
              const ds = [];
              ds.push(updateDatasetResult.data)

              navigate("/dataset/" + updateDatasetResult?.data.title_abbr.toLowerCase(), {
                state: {
                  dataset: ds
                }
              });
            })
            .catch((err) => {
              console.log("There is problem on updating dataset", err)
            })
        } else {
          //roll back 
          if (addDataResult?.status && !renameDataTableResult?.status)
            await dropDataTable(`temp_tab_${d_id}_${d_data_name}`)
          else if (addDataResult?.status && renameDataTableResult?.status)
            await dropDataTable(`${addDataResult?.data.data_name}`)

          if (moveDataFiles?.status)
            await deleteDataInFolder(`temp_file_${d_id}_${d_data_name}`)
          else
            await deleteDataInTempFolder(`temp_file_${d_id}_${d_data_name}`)

          if (addDataResult?.status)
            await deleteData(d_id)
        }

        toast('There is problem in data upload')

        setIsEditing(false)
        setEditDId(null)
      } catch (err) {
        console.log(err)
      }
    } else {
      //Update Exiting data
      let update_data = dataInDatasetVersioned?.data?.flat().filter((d) => d.data_id === editdid)[0]

      if (d_tag !== update_data.tag && d_tag !== null && d_tag !== "") update_data.tag = d_tag
      if (d_data_desc !== update_data.d_data_desc && d_data_desc !== null && d_data_desc !== "") update_data.data_desc = d_data_desc
      if (d_data_name !== update_data.d_data_name && d_data_name !== null && d_data_name !== "") update_data.data_name = d_custom_data_name !== null && d_custom_data_name !== "" && d_custom_data_name !== d_data_name ? d_custom_data_name : d_data_name
      if (d_permission !== update_data.d_permission && d_permission !== null && d_permission !== "") update_data.permission = d_permission
      if (d_publish_date !== update_data.d_publish_date && d_publish_date !== null) update_data.publish_date = d_publish_date
      if (d_status !== update_data.d_status && d_status !== null) update_data.status = d_status
      if (d_contact !== update_data.d_contact) update_data.contact = d_contact
      if (d_category !== update_data.d_category) update_data.category = d_category
      if (d_title !== update_data.d_title) update_data.title = d_title

      update_data.last_updated_by = portalUser?.uid
      update_data.last_updated_date = getCurrentDateTime()

      /* Handle data name update */
      if (d_custom_data_name !== null && d_custom_data_name !== "" && d_custom_data_name !== d_data_name) {
        const renameDataTableResult = await renameTable(d_data_name, d_id, edit_session_id, d_custom_data_name)

        let renameDataFileResult;
        if (update_data.is_spatial) {
          renameDataFileResult = await renameDataFile(`${d_data_name}.geojson`, `${d_custom_data_name}.geojson`)
        }
        else {
          renameDataFileResult = await renameDataFile(`${d_data_name}.csv`, `${d_custom_data_name}.csv`)
        }

        const renameDataDictResult = await renameDataFile(`${d_data_name}_datadict.csv`, `${d_custom_data_name}_datadict.csv`)
      }

      /* handle files re-uploaded */
      if (uploadResponse?.upload_success) {
        //const moveDataFiles = await moveDataToDownload(d_data_name, d_custom_data_name, d_format, d_id, edit_session_id)
        const moveDataFiles = await moveDataToDownload(d_data_name, d_custom_data_name, update_data.format, update_data.data_id, edit_session_id)

        //const renameDataTableResult = await renameTable(d_data_name, d_id, edit_session_id, d_custom_data_name)
        const renameDataTableResult = await renameTable(d_data_name, update_data.data_id, edit_session_id, d_custom_data_name)

      }

      updateData(update_data, editdid)
        .then(async (result) => {

          setIsEditing(false)
          setEditDId(null)

          const dataIndatasetVerioned = await getDataInDatasetByDatasetAbbrVersionedAdmin()
          setDataInDatasetVersioned(dataIndatasetVerioned)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    setUploadResponse(null)
  }

  const handleDeleteData = async (data_id) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete this data (data_id=${data_id})?`);

    if (confirmDelete) {
      setUploadResponse(null)
      setIsLoading(true)

      //1. Delete the data from dp_data table
      deleteData(data_id)
        .then(async deleteDataResult => {
          //2. Drop the data table in DB
          dropDataTable(deleteDataResult?.data?.data_name)
            .then(dropDataTableResult => {
              //3. Delete csv/geojson in folder
              deleteDataInFolder(deleteDataResult?.data?.data_name)
                .then(deleteDataInFolderResult => {
                  //4. Update dataset on DB table
                  //4a. Update the dataset in current view
                  let current_viewing_dataset = location.state?.dataset?.length > 0 ? location.state?.dataset : dataset

                  let arr_updated_data_id = current_viewing_dataset[0]?.data_id?.replaceAll(" ", "").trim().split(",")
                  arr_updated_data_id?.splice(arr_updated_data_id?.indexOf(data_id?.toString()), 1)
                  current_viewing_dataset[0].data_id = arr_updated_data_id?.toString()

                  updateDataset(current_viewing_dataset[0])
                    .then(updateDatasetResult => {
                      //5. Refresh the page and update the state
                      //A. dataInDatasetVersioned
                      const updated_dataInDatasetVersioned = dataInDatasetVersioned?.data?.map(subArray => {
                        const filteredArray = subArray.filter(item => item?.data_id !== data_id);
                        return filteredArray.length > 0 ? filteredArray : null;
                      }).filter(Boolean);

                      if (updated_dataInDatasetVersioned.length > 0) {
                        setDataInDatasetVersioned(prevState => ({
                          ...prevState,
                          data: updated_dataInDatasetVersioned,
                        }))
                      } else {
                        setDataInDatasetVersioned(prevState => ({
                          ...prevState,
                          data: updated_dataInDatasetVersioned,
                          message: "All data in this data collection has been removed",
                        }))
                      }
                      //B. dataset
                      setDataset(prevVal => [
                        { ...prevVal[0], data_id: updateDatasetResult?.data?.data_id },
                        ...prevVal.slice(1)
                      ])

                      //setIsLoading(false)
                    })

                  //4b. Get all the dataset(s) containing this data_id; 
                  //Then exclude the current viewing one and loop through them and update them
                  getDatasetsByDataIdAdmin(data_id)
                    .then(datasets_involved => {
                      if (datasets_involved?.data?.length > 0) {
                        //Exclude the current dataset from the list
                        const datasets_involved_filtered = [...datasets_involved?.data?.filter(ds => ds?.dataset_id !== current_viewing_dataset[0]?.dataset_id)]

                        datasets_involved_filtered?.map(async dataset_to_be_updated => {
                          let arr_updated_data_id = dataset_to_be_updated?.data_id?.replaceAll(" ", "").trim().split(",")
                          arr_updated_data_id?.splice(arr_updated_data_id?.indexOf(data_id?.toString()), 1)
                          dataset_to_be_updated.data_id = arr_updated_data_id?.toString()
                          await updateDataset(dataset_to_be_updated)

                        }) //End of Loop through datasets                        
                        window.alert(`This data is also available in the following dataset(s): \n${datasets_involved?.data?.map((ds, index) => index + 1 + ". " + ds?.title + " (dataset id=" + ds?.dataset_id + ")").join("\n")}\n\nThe data will also be removed accordingly.`);
                      }
                      setIsLoading(false)

                      navigate("/dataset/" + current_viewing_dataset[0]?.title_abbr?.toLowerCase(), {
                        state: {
                          dataset: current_viewing_dataset,
                        }
                      });
                    }) //End of getDatasetByDataIdAdmin
                })
            })
        })
    }
  }

  /*const handleCancelEditData = async (data_id) => {  
    if (data_id.toString().match(/[A-Za-z]/g)?.length > 0) {
      filteredDataInDataset.data.shift()
    }  
    setIsEditing(false)
    setEditDId()
  
    if (uploadResponse?.upload_result) {
      //Refresh the page and update the state
      const dds = await getDataInDatasetByDatasetAbbrAdmin()
      setDataInDataset(dds)
      setFilteredDataInDataset(dds)
    }
  }*/

  const handleCancelEditData = async (cancel_data_id) => {
    setUploading(false)
    setEditVersion()
    setIsEditing(false)
    setEditDId()

    const foundNewData = dataInDatasetVersioned?.data?.flat()?.find(d => d.data_id === cancel_data_id)

    if (foundNewData && TEMP_EDIT_ID_REGEX.test(foundNewData.data_id) === true) {
      dataInDatasetVersioned?.data?.shift()
    }

    if (uploadResponse?.upload_success) {
      setIsEditing(false)
      setUploading(false)
      //Refresh the page and update the state
      const dataIndatasetVerioned = await getDataInDatasetByDatasetAbbrVersionedAdmin()
      setDataInDatasetVersioned(dataIndatasetVerioned)
    }

    const ds = location.state?.dataset ? location.state?.dataset : dataset
    navigate("/dataset/" + ds[0]?.title_abbr?.toLowerCase(), {
      state: {
        dataset: ds,
      }
    });
  }

  //Get all user ids (for displaying created_by and last_updated_by in admin portal)
  const getUniqueUserIds = async (data_in_dataset) => {
    return new Promise(async (resolve, reject) => {
      let userIdsSet = new Set();
      if (data_in_dataset?.length > 0) {
        data_in_dataset?.forEach(sublist => {
          sublist.forEach(dsd => {
            if (!userIdsSet.has(dsd.created_by) && dsd.created_by !== null) {
              userIdsSet.add(dsd.created_by)
            }

            if (!userIdsSet.has(dsd.last_updated_by) && dsd.last_updated_by !== null) {
              userIdsSet.add(dsd.last_updated_by)
            }
          });
        });

        resolve([...userIdsSet]);
      } else {
        resolve("");
      }
    });
  };

  //Get user by ids (for displaying created_by and last_updated_by in admin portal)
  const getUserByIds = async (uids) => {
    if (uids === "") return {};
    const accessToken = await refreshToken();
    return new Promise(async (resolve, reject) => {
      //setIsLoading(true)
      fetch(api_url + "/admin/users?user_ids=" + encodeURIComponent(uids), {
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res_json) => {

          //setIsLoading(false);
          if (res_json?.data?.length > 0) {
            const usersById = res_json?.data?.reduce((acc, user) => {
              acc[user.uid] = user;
              return acc;
            }, {});

            resolve(usersById);
          }
          resolve({});
        });
    });
  };

  const DatasetDetails = (props) => {
    if (!!props.dataset_desc) {
      return (
        <>
          <div className='m-3'>
            <Row className='mt-1'>
              <Col xs={3} sm={3} md={6} lg={2} xl={2}>
                <strong>Published:</strong>
              </Col>
              <Col xs={6} sm={6} md={6} lg={10} xl={10}>
                {props?.dataset_desc?.publish_date?.slice(0, 10)}
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col xs={3} sm={3} md={6} lg={2} xl={2}>
                <strong>Contact:</strong>
              </Col>

              <Col xs={6} sm={6} md={6} lg={10} xl={10}>
                <span> <a href={`mailto:${props?.dataset_desc?.contact}`} >
                  {props?.dataset_desc?.contact}
                </a>
                </span>
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col xs={3} sm={3} md={6} lg={2} xl={2}>
                <strong>Dataset:</strong>
              </Col>
              <Col xs={6} sm={6} md={6} lg={10} xl={10}>
                <span className="border-dark"> {props?.dataset_desc?.title}</span>
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col xs={3} sm={3} md={6} lg={2} xl={2}>
                <strong>Tags:</strong>
              </Col>
              <Col xs={9} sm={9} md={6} lg={10} xl={10} className='d-flex justify-content-start'>
                <div>
                  {
                    (!!props?.dataset_desc?.tag === true)
                      ?
                      (props?.dataset_desc?.tag)?.split(",")?.map((t, index) => {
                        return (<Badge className="me-2 mt-1 ps-2 pe-2" key={t + "_" + index} bg="dark">{t}</Badge>)
                      })
                      : ""
                  }
                </div>
              </Col>
            </Row>
          </div>
        </>
      )
    }
    else {
      return (<>
        <div>{no_dataset_detail}</div>
      </>)
    }
  }

  //render table showing `data` in selected dataset
  const DataList = () => {
    return (
      (dataInDatasetVersioned?.data?.length === 0 && !dataInDatasetVersioned?.message)
        ? <>
          <div className="d-flex justify-content-center" style={{ marginTop: "50px" }}>
            <Spinner animation="border" role="status" >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </>
        : //(Object.keys(filteredDataInDataset).includes('data') && filteredDataInDataset.data.length > 0)
        dataInDatasetVersioned?.data?.length > 0 ||
          (dataInDatasetVersioned?.data?.length > 0 && !!data_meta)
          ? <>
            {/*<animated.div style={{...props}}>*/}
            {
              dataInDatasetVersioned?.data?.map((dataVersioned, dataIndex) => {
                return (
                  <DataCard
                    key={`edit_${dataIndex}`}
                    data={dataVersioned}
                    dataset={!!location.state?.dataset ? location.state.dataset[0] : dataset[0]}
                    data_meta={data_meta}
                    handleDownload={handleDownload}
                    cart={cart}
                    setCart={setCart}
                    accessToken={accessToken}
                    loggedInUser={portalUser}
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    editdid={editdid}
                    set_edit_session_id={set_edit_session_id}
                    edit_session_id={edit_session_id}
                    batchUploadFiles={batchUploadFiles}
                    uploadResponse={uploadResponse}
                    handleSaveData={handleSaveData}
                    handleEditData={handleEditData}
                    handleCancelEditData={handleCancelEditData}
                    //handleCancelEditData2={handleCancelEditData2}
                    handleDeleteData={handleDeleteData}
                    uploading={uploading}
                    editversion={editVersion}
                    loading={isLoading}
                  />
                )
              })
            }

            {/*</animated.div>*/}
          </>
          : dataInDatasetVersioned?.message
            ? <>
              <div className="mt-4 d-felx align-items-center text-center ">
                <i>{dataInDatasetVersioned.message}</i>
              </div>
            </>
            : <></>
    )
  }

  /*const props = useSpring({
    from: { x : -50, y : 0, opacity: 0.5 },
    to: { x : 0, y: 0, opacity : 1 },    
    config: {
      mass: 5,
      friction: 50,
      tension: 270,
      precision: 0.0001,
    },
  })*/

  return (
    <>
      <HeaderNew
        isLoading={isLoading}
        CartCount={cart ? cart.length : 0}
        loggedInUser={portalUser}
        setLoggedInUser={setPortalUser}
        dataset={location.state?.dataset ? location.state.dataset : dataset}
        data={null}
        keyword={keyword}
        setKeyword={setKeyword}
      />
      <div style={{ zIndex: 0, touchAction: "pan-y" }}>
        <Row className="d-flex justify-content-center align-items-center">
          <Col xs={10} sm={10} md={10} lg={8} xl={7}>
            <DatasetInfo
              dataset={location.state?.dataset?.length === 1 ? location.state.dataset[0] : dataset[0]}
              isEditing={isEditing}
              editdsid={editdsid}
              handleSaveDataset={handleSaveDataset}
              handleEditDataset={handleEditDataset}
              handleCancelEditDataset={handleCancelEditDataset}
              handleDeleteDataset={handleDeleteDataset}
            />
          </Col>
          {
            dataInDatasetVersioned?.data?.length > 0 ||
              (dataInDatasetVersioned?.data?.length === 0 && dataInDatasetVersioned?.message)
              ?
              <>
                <Col xs={10} sm={10} md={10} lg={8} xl={7}>
                  <Tabs defaultActiveKey="data" id="uncontrolled-data-listing-tab">
                    {
                      <Tab className=""
                        eventKey="data"
                        title={dataInDatasetVersioned?.data?.filter(item => item.every(item => typeof item?.data_id === 'number'))?.length > 0
                          ? "Data (" + dataInDatasetVersioned?.data?.filter(item => item.every(item => typeof item.data_id === 'number')).length + ")"
                          : "Data"
                        }
                      >
                        {/* Data filtering */}
                        <Stack direction="horizontal" className="p-2">
                          <FontAwesomeIcon className="" size="1x" icon={icon({ name: 'magnifying-glass' })} />
                          <Form.Control
                            className="shadow-none ms-1 me-1 ps-1 pe-1"
                            size="sm"
                            type="text"
                            placeholder="Search..."
                            onChange={handleFilterChange}
                            value={filteredInput}
                            style={{ borderRadius: '0px', border: 'none', borderBottom: '1px gray solid' }}
                            disabled={dataInDatasetVersioned?.data?.length === 0 && (filteredInput === '' || filteredInput === null || filteredInput === undefined)}
                          />
                        </Stack>
                        {
                          /* If it is an admin, display the add data button */
                          (isAdminPortal && portalUser?.role.split(",").includes(permission_admin))
                            ? <>
                              <Col xs={12} sm={12} md={12} lg={12} xl={12} className='pt-2 pb-1 d-flex justify-content-center'>
                                <Button disabled={isEditing} className='om-admin-button border-0' onClick={handleAddData}>
                                  <FontAwesomeIcon size="1x" icon={icon({ name: 'circle-plus' })} />
                                  <span>&nbsp;Add Data</span>
                                </Button>
                              </Col>
                            </>
                            : <></>
                        }
                        {
                          isLoading === true
                            ? <>
                              <div className="d-flex justify-content-center" style={{ marginTop: "50px" }}>
                                <Spinner animation="border" role="status" >
                                  <span className="visually-hidden">Loading...</span>
                                </Spinner>
                              </div>
                            </>
                            : <>
                              <DataList />
                            </>
                        }
                      </Tab>
                    }
                    {/* Citation */}
                    <Tab className="" eventKey="citation" title="Citation">
                      <CitationDisplay data_desc_md={location.state?.dataset?.length > 0 ? location.state?.dataset[0] : dataset[0]} />
                    </Tab>
                    {/* Details */}
                    <Tab className="" eventKey="details" title="Details">
                      <DatasetDetails dataset_desc={location.state?.dataset?.length > 0 ? location.state?.dataset[0] : dataset[0]} />
                    </Tab>

                  </Tabs>
                </Col>
              </>
              : <>
                <div className="d-flex justify-content-center" style={{ marginTop: "50px" }}>
                  <Spinner animation="border" role="status" >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </>
          }
        </Row>
      </div>
    </>
  )

};

export default ViewDatasetByName;